import { createAsyncThunk } from '@reduxjs/toolkit'
import adminAPI from '../../services/adminApi'
import gameAPI from '../../services/gameApi'
import APIError from '../../errors/APIError'
import { DeviceResponse } from '../../types/gameApi/device'
import { getOrRegisterDevice } from '../device/deviceActions'
import { ParticipantDetailsResponse } from '../../types/adminApi/events'
import { ContentResponse } from '../../types/gameApi/content'
import { addVariables } from '../../lib/googleTagManager'

export const initialise = createAsyncThunk<
  {
    device: DeviceResponse
    event: ParticipantDetailsResponse
    content: ContentResponse
  },
  { participantId: string; eventId: string; isTablet: boolean },
  { rejectValue: Error | APIError }
>(
  'common/initialise',
  async ({ eventId, participantId, isTablet }, { rejectWithValue }) => {
    try {
      const eventResponse = await adminAPI.getParticipantDetails({
        eventId,
        participantId,
      })

      const deviceResponse = await getOrRegisterDevice({
        deviceId: participantId,
        team: eventResponse.team,
        isMaster: eventResponse.isCeo,
      })

      const contentResponse = await gameAPI.getContent()

      addVariables({
        eventId,
        participantId,
        isTablet,
      })

      return {
        event: eventResponse,
        device: deviceResponse,
        content: contentResponse,
      }
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)
