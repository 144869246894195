import { ParticipantDetailsResponse } from '../../../../../types/adminApi/events'

export const getParticipantDetailsData = (): ParticipantDetailsResponse => {
  return {
    team: 1,
    isCeo: true,
    apiHost: '',
    eventId: 'abcde',
    eventDate: '2023-01-01',
    firstName: 'Demo',
    participantId: '12345',
    client: 'Excelerate',
    gameName: 'Banking Simulation',
    eventClosed: false,
    eventLogo:
      'https://test-images.banksim.co/brandings/images/e51cb2747d7c4d766aa62edc5a1f2548.png',
    headingBackgroundColour: '#1E242B',
    mainBackgroundColour: '#F7F7F7',
    buttonBackgroundColour: '#38A9E4',
    headingTextColour: '#FFFFFF',
    mainTextColour: '#1E242B',
    buttonTextColour: '#FFFFFF',
    insideAppText: 'Demo',
  }
}
