import { createSlice } from '@reduxjs/toolkit'
import { userLogin } from './authActions'
import { getAuthDetails, removeAuthDetails } from '../../lib/localStorage'
import APIError from '../../errors/APIError'
import adminAPI from '../../services/adminApi'
import gameAPI from '../../services/gameApi'
import { initialise } from '../common/commonActions'

// initialize userToken from local storage
const authDetails = getAuthDetails()

interface AuthState {
  isLoading: boolean
  isLoggedIn: boolean
  token: string | null
  eventId: string | null
  participantId: string | null
  email: string | null
  isTablet: boolean
  isPreviewMode: boolean
  error: Error | APIError | null
}

const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: false,
  token: authDetails?.token ?? null,
  eventId: authDetails?.eventId ?? null,
  participantId: authDetails?.participantId ?? null,
  email: authDetails?.email ?? null,
  isTablet: authDetails?.isTablet ?? false,
  isPreviewMode: authDetails?.isPreviewMode ?? false,
  error: null,
}

if (initialState.token) {
  adminAPI.setToken(initialState.token)
  gameAPI.setToken(initialState.token)
  gameAPI.setGameId(initialState.eventId)
  adminAPI.setEventId(initialState.eventId)
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      removeAuthDetails()
      adminAPI.setToken(null)
      gameAPI.setToken(null)
      state.isLoading = false
      state.isLoggedIn = false
      state.token = null
      state.eventId = null
      state.participantId = null
      state.email = null
      state.isTablet = false
      state.isPreviewMode = false
      state.error = null
    },
    // setCredentials: (state, { payload }) => {
    //   state.userInfo = payload
    // },
  },
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isLoggedIn = true
        state.token = payload.auth.token
        state.eventId = payload.auth.eventId
        state.participantId = payload.auth.participantId
        state.email = payload.auth.email
        state.isTablet = payload.auth.isTablet
        state.isPreviewMode = payload.auth.isPreviewMode
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload ?? null
      })
      .addCase(initialise.fulfilled, state => {
        state.isLoggedIn = true
      })
      .addCase(initialise.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isLoggedIn = false
        state.token = null
        state.eventId = null
        state.participantId = null
        state.email = null
        state.error = state.isTablet ? payload ?? null : null
      })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
